import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Appear from './Appear';
import Box from './atoms/Box';
import Flex, { BoxProps } from './atoms/Flex';

interface Props extends BoxProps {
    left?: ReactNode;
    leftWidth?: BoxProps['width'];
    right?: ReactNode;
    rightWidth?: BoxProps['width'];
    className?: string;
}
const Content: FC<Props> = ({ left, className, right, leftWidth, rightWidth, ...props }) => {
    return (
        <Container alignItems="center" className={className} {...props}>
            <Box flex={!leftWidth ? 1 : undefined} style={{ overflow: 'hidden' }} width={leftWidth}>
                <Appear
                    threshold={0.2}
                    style={{ height: '100%' }}
                    from={{ transform: 'scale(0.9)', opacity: 0.5 }}
                    to={{ transform: 'scale(1)', opacity: 1 }}
                >
                    {left}
                </Appear>
            </Box>
            <Box flex={!rightWidth ? 1 : undefined} width={rightWidth}>
                <Appear from={{ opacity: 0, transform: 'translateY(5vh)' }}>{right}</Appear>
            </Box>
        </Container>
    );
};

export default Content;

const Container = styled(Flex)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        image {
            object-fit: cover;
            max-height: 40vh;
        }

        > * + * {
            margin-top: 2.4rem;
        }
    }
`;
