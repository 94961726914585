import React, { ComponentPropsWithRef, ComponentType } from 'react';
import Body, { BodyProps } from './atoms/Body';
import Feature from './atoms/Feature';
import styled from 'styled-components';

export const Item = ({
    children,
    variant,
    ...props
}: ComponentPropsWithRef<'li'> & Pick<BodyProps, 'variant'> & { as?: ComponentType }) => (
    <StyledItem {...props}>
        <Feature />
        <Body variant={variant}>{children}</Body>
    </StyledItem>
);

export const StyledItem = styled.li`
    display: flex;
    list-style: none;

    + li {
        margin-top: 0.8rem;
    }
    > svg {
        flex-shrink: 0;
        margin-right: 1rem;
    }
`;

const List = styled.ul`
    padding: 0;
    margin-left: 0.8rem;
`;

export default List;
