import React, { ComponentProps } from 'react';

const Feature = (props: ComponentProps<'svg'>) => (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 1.8514l-.9007 1.3877c-.3493.5382-.8688 1.233-1.3551 1.8833-.1005.1344-.1996.2669-.2954.3956-.6162.8281-1.0779 1.477-1.2636 1.8374-.231.4482-1.2227 2.043-2.1439 3.5011-.932 1.475-1.9277 3.024-2.2032 3.405-1.142 1.5791-1.4502 2.1123-1.7478 2.6271l-.013.0226c-.3602.6231-.7219 1.2429-1.9438 2.9324-.1431.1978-.3724.4812-.5326.6792-.0708.0875-.1281.1583-.1585.1977-.1737.2249-.2592.3694-.3087.4966-.2392.6141-.6137 1.0763-.8926 1.3883a14.0603 14.0603 0 01-.0425.0937c-.0364.0794-.1043.2267-.1891.3705a2.2668 2.2668 0 01-.2078.2978c-.083.0998-.2668.3037-.5613.455-.7177.3686-1.347.0563-1.4362.012l-.0043-.0021a1.7248 1.7248 0 01-.3466-.2274 2.2733 2.2733 0 01-.233-.2292c-.0945-.1059-.1934-.2317-.2785-.3432-.1781-.2334-.3967-.5378-.6032-.8296a139.5218 139.5218 0 01-.4369-.6218 229.9682 229.9682 0 00-.144-.2057c-.0758-.1081-.13-.1845-.1664-.2357-.0631-.0889-.0722-.1017-.0463-.0721-.3101-.3539-.572-.847-.735-1.1746-.1855-.3727-.351-.7556-.4541-1.0202-.0025-.0046-.0237-.0447-.1076-.1598a15.8216 15.8216 0 00-.0427-.058c-.0439-.0594-.1058-.1433-.1498-.2051a4.4047 4.4047 0 01-.2628-.4118c-.8777-1.5777-1.7344-3.0726-2.8188-4.5578L0 12.1461l2.5964-2.0025.9741 1.3342c1.215 1.6639 2.1563 3.3149 3.0405 4.9043.0238.0427.046.073.1194.1726.0293.0398.0668.0907.1158.1579.1357.1861.3608.5031.5222.9174.0675.1734.1925.4642.3315.7435.069.1386.1341.2595.1905.3541.049.0823.074.1152.0738.1154l-.005-.0056c.079.0904.166.2118.2057.2673l.0062.0087c.0598.0833.1337.1882.2137.3023.1682-.3029.3516-.5576.5147-.769.1534-.1986.2533-.3193.338-.4217.0974-.1178.1749-.2114.2907-.3716 1.1396-1.5758 1.4474-2.1082 1.7448-2.6229l.0136-.0235c.3603-.6232.7228-1.2441 1.9463-2.9358.1977-.2734 1.1222-1.7064 2.0859-3.2317.9743-1.5422 1.8537-2.9661 1.9979-3.2458.3395-.6587.9937-1.55 1.5536-2.3024.1113-.1495.221-.2963.328-.4395.4859-.6504.9179-1.2285 1.2006-1.664L21.2995 0 24 1.8514z"
            fill="currentColor"
        />
    </svg>
);

export default Feature;
