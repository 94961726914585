import React, { SVGAttributes } from 'react';

const ActionDraw = (props: SVGAttributes<SVGElement>) => (
    <svg
        id="b589f143-9323-4ccb-a169-5e39553f7adc"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="893.86671"
        viewBox="0 0 893.86671 645.61588"
        {...props}
    >
        <path
            d="M710.155,772.46457c8.7994-19.0061,17.59212-38.294,21.411-58.88715s2.216-42.94256-8.72123-60.80421-32.47257-29.97377-53.02392-25.93606c-16.87949,3.31631-30.433,16.61066-38.36911,31.8728s-11.12022,32.47634-14.2069,49.39933c-.99741-19.22573-2.05375-38.76088-8.44037-56.92222s-18.9625-35.08591-36.88552-42.11361-41.23853-1.50125-50.685,15.27337c-13.30848,23.63268,4.33809,56.78211-10.96485,79.17495-2.62495-22.46189-26.11415-40.20841-48.43856-36.59621s-39.01861,27.86061-34.42617,50.00415c2.73263,13.176,11.72251,24.38309,22.47045,32.47982s23.24822,17.48084,35.61391,22.78792Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f2f2f2"
        />
        <path
            d="M439.1256,682.79534c17.73322,6.19837,35.62952,12.46691,51.819,22.15758,14.50966,8.68517,27.43029,20.37884,35.59045,35.35409a62.87057,62.87057,0,0,1,7.77662,26.1215c.11782,1.918,3.11864,1.93139,3,0-1.04914-17.07862-9.37222-32.70794-20.89747-45.06835-12.63618-13.55183-29.01094-22.80755-45.91719-29.98889-10.02408-4.258-20.29727-7.87674-30.5739-11.46877-1.82619-.63832-2.61128,2.25886-.79751,2.89284Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#fff"
        />
        <path
            d="M541.41225,612.05107a270.25482,270.25482,0,0,1,25.99431,57.706,273.52447,273.52447,0,0,1,11.98965,62.14084,270.11259,270.11259,0,0,1,.28557,35.50479c-.11131,1.92873,2.88906,1.92233,3,0a273.6709,273.6709,0,0,0-3.84571-63.74681,276.93584,276.93584,0,0,0-18.41325-61.1452,270.01059,270.01059,0,0,0-16.42016-31.97377,1.50112,1.50112,0,0,0-2.59041,1.51416Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#fff"
        />
        <path
            d="M688.24374,629.2835a437.36812,437.36812,0,0,0-32.26212,108.52723q-2.53,15.76657-3.91657,31.68787c-.16772,1.923,2.83326,1.91173,3,0a435.79571,435.79571,0,0,1,24.00908-109.36938q5.34134-14.87545,11.76-29.33156c.77666-1.749-1.80757-3.27706-2.59041-1.51416Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#fff"
        />
        <path
            d="M259.1357,770.22063A141.78086,141.78086,0,0,1,153.08292,630.82672c.12189-7.912.95378-15.99006,4.27976-23.1701s9.50394-13.39014,17.23186-15.09142c8.43389-1.85671,17.33349,1.93874,23.61873,7.861s10.40636,13.736,14.198,21.495c22.83782,46.734,40.18948,96.87978,46.6859,148.48823Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f2f2f2"
        />
        <path
            d="M170.76834,594.12445l16.00606,36.36706,16.006,36.36707c5.06915,11.51753,10.12863,23.04011,15.36814,34.48151,5.1935,11.34092,10.56495,22.6034,16.29525,33.684,5.72218,11.06486,11.80377,21.94913,18.40506,32.51522q1.23309,1.9737,2.49042,3.93207c.58008.90424,2.03014.06546,1.44594-.84519-6.72792-10.48764-12.92723-21.30541-18.7416-32.32388-5.82065-11.03036-11.2609-22.25747-16.5002-33.57426-5.26729-11.37724-10.332-22.84667-15.38325-34.321q-7.97541-18.11688-15.948-36.235l-16.00606-36.36706-1.99186-4.52568c-.43316-.98418-1.87645-.13294-1.446.84519Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#fff"
        />
        <path
            d="M252.45875,770.47342a82.96675,82.96675,0,0,1-92.692-43.74067c-2.07267-4.1407-3.82153-8.55925-4.03308-13.1849s1.31945-9.51764,4.87237-12.48717c3.87749-3.2408,9.52264-3.67283,14.38463-2.29485s9.111,4.32216,13.17418,7.32688c24.47345,18.09816,47.01961,39.44869,64.3248,64.48913Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f2f2f2"
        />
        <path
            d="M159.04053,702.90315l18.12782,14.56013,18.12782,14.56012c5.74112,4.61123,11.47858,9.22769,17.2876,13.7534,5.758,4.486,11.58721,8.88316,17.55363,13.089,5.95795,4.19991,12.05376,8.209,18.33348,11.91267q1.173.69182,2.3545,1.36914c.54528.31286,1.07169-.51408.52254-.82916-6.32429-3.62871-12.46326-7.57154-18.45656-11.72248-5.99977-4.15542-11.85515-8.51568-17.63042-12.97675-5.80612-4.4849-11.53195-9.07234-17.25211-13.666q-9.03159-7.2529-18.062-14.50723L161.819,703.88592l-2.25591-1.81193c-.49058-.394-1.01011.43755-.52254.82916Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#fff"
        />
        <path
            d="M1024.03257,772.80794h-827.294a1.19069,1.19069,0,1,1,0-2.38137h827.294a1.19068,1.19068,0,1,1,0,2.38137Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M1002.2998,653.51542H946.29a2.501,2.501,0,0,1-2.5,2.5h-54.98a12.85953,12.85953,0,0,0-2.10986,2,12.41026,12.41026,0,0,0-2.9004,8v2a12.50461,12.50461,0,0,0,12.5,12.5h106a12.51087,12.51087,0,0,0,12.5-12.5v-2A12.51734,12.51734,0,0,0,1002.2998,653.51542Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <rect x="790.22837" y="552.82677" width="13" height="92" fill="#3f3d56" />
        <path
            d="M993.295,771.56214c0,1.40463-19.69947.54331-44,.54331s-44,.86132-44-.54331,19.69948-12.54331,44-12.54331S993.295,770.15751,993.295,771.56214Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M999.8501,656.39542l-2.18018-13.63995a6.57445,6.57445,0,0,0-7.52-5.45l-22.48974,3.59-38.52,6.15-12.53028,2H943.79a2.501,2.501,0,0,1,2.5,2.5v1.97a2.501,2.501,0,0,1-2.5,2.5H874.62012a6.33168,6.33168,0,0,0-2.43018,2,6.48657,6.48657,0,0,0-1.23974,4.96l.61962,3.88.75,4.71.81006,5.06a6.56087,6.56087,0,0,0,7.5,5.44l17.87012-2.85,51.54-8.23,44.35986-7.08A6.58713,6.58713,0,0,0,999.8501,656.39542Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M897.12988,658.01542a6.35633,6.35633,0,0,0-2.52978-2H875.48a6.32109,6.32109,0,0,0-2.54,2,6.4652,6.4652,0,0,0-1.37012,3.99v76.21a6.55961,6.55961,0,0,0,6.5503,6.55H891.9502a6.5595,6.5595,0,0,0,6.5498-6.55v-76.21A6.48364,6.48364,0,0,0,897.12988,658.01542Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M938.75,528.21543l-.12988-.17-8.58008-10.57a6.31523,6.31523,0,0,0-1.62988-1.43,4.81323,4.81323,0,0,0-1.06006-.54,6.3,6.3,0,0,0-1.71-.42,6.64752,6.64752,0,0,0-1.18018-.02.03158.03158,0,0,0-.02.01,6.48523,6.48523,0,0,0-3.62012,1.44l-17.60986,14.28-22.62988,18.36-33.77,27.4-2.46,2h42.73l2.46972-2,8.17041-6.63.57959-.46,39.46-32.01.03027-.02a6.45665,6.45665,0,0,0,1.87012-2.45,6.59908,6.59908,0,0,0,.52-1.97A6.501,6.501,0,0,0,938.75,528.21543Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M938.62012,528.04545a16.30318,16.30318,0,0,0-3.56006-7.3,16.11787,16.11787,0,0,0-6.6499-4.7c-.40039-.15-.79-.29-1.19-.4a17.14849,17.14849,0,0,0-2.76025-.57995.03158.03158,0,0,0-.02.01,16.3141,16.3141,0,0,0-11.7998,3.26l-30.46,23.28a6.46158,6.46158,0,0,0-2.14991,4.57,6.63325,6.63325,0,0,0,.54981,2.97c.1001.21.21.42.33008.62a5.75583,5.75583,0,0,0,.83984,1.12l4.87988,5.48,11.3501,12.73.31982.36.73047.83a6.54939,6.54939,0,0,0,9.26953.41l26.27-28.22a16.21369,16.21369,0,0,0,3.18995-5.03A16.466,16.466,0,0,0,938.62012,528.04545Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <circle cx="797.16693" cy="314.52053" r="50.12206" fill="#f8bc93" />
        <path
            d="M969.03027,649.49546l-1.37011-8.6-.81006-5.05a6.56327,6.56327,0,0,0-7.51026-5.44l-36.42968,5.81-9.87989,1.58-2,.32v10.93H943.79a2.501,2.501,0,0,1,2.5,2.5v1.97a2.501,2.501,0,0,1-2.5,2.5H840.12012c0,.02.00976.04.00976.06l.62012,3.88,1.56006,9.76a6.56058,6.56058,0,0,0,7.5,5.44l17.87012-2.85,3.88964-.62.75-.12,26.18018-4.18,17.81006-2.85,28.25976-4.51.42041-.07,18.58985-2.96A6.56824,6.56824,0,0,0,969.03027,649.49546Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M1018.4502,612.71543c0,40.86-4.1504,65.3-68.1504,58.3-.08984-.01-.16992-.02-.25976-.03a190.03023,190.03023,0,0,1-33.73-6.45,100.17352,100.17352,0,0,1-16.64014-6.52c-1.25976-.64-2.46972-1.31-3.66015-2H943.79a2.501,2.501,0,0,0,2.5-2.5v-1.97a2.501,2.501,0,0,0-2.5-2.5H911.03027v-63.16a7.34062,7.34062,0,0,0-7.33007-7.33H877.0498c.23047-.66.46-1.33.71-2a163.82906,163.82906,0,0,1,8.87011-20.18,153.93262,153.93262,0,0,1,16.58008-25.58,106.5314,106.5314,0,0,1,14.83984-15.19c10.03028-8.28,20.9502-13.4,32.03028-13.4C990.93994,502.20542,1018.4502,571.85545,1018.4502,612.71543Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M951.28664,460.2895l.05213-1.873c-3.48453-.09694-6.55659-.31553-8.865-2.002a5.75773,5.75773,0,0,1-2.2302-4.24088,3.2913,3.2913,0,0,1,1.07966-2.71081c1.53237-1.29412,3.99807-.87524,5.79521-.05121l1.54975.71062-2.97192-21.71843-1.85567.25426,2.52789,18.47443a6.61182,6.61182,0,0,0-6.25433.89994,5.1237,5.1237,0,0,0-1.74227,4.20705,7.61644,7.61644,0,0,0,2.99707,5.68774C944.335,460.0947,948.18943,460.20262,951.28664,460.2895Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <rect x="771.5089" y="303.05912" width="10.08777" height="1.87305" fill="#2f2e41" />
        <rect x="803.35076" y="303.05912" width="10.08777" height="1.87305" fill="#2f2e41" />
        <path
            d="M990.23519,427.94119c-3.89814-5.86788-10.75722-8.35524-17.521-8.96031-7.61315-.68107-14.96945.787-22.40249,2.21331-8.18044,1.56974-16.83815,3.03841-25.08093,1.05378a24.725,24.725,0,0,1-16.80712-13.5776,31.62746,31.62746,0,0,1-1.84144-21.08877c1.93156-7.47935,6.726-14.06049,12.53819-19.0312,11.86273-10.14531,27.916-14.70479,43.35645-14.27531a73.301,73.301,0,0,1,42.49444,14.9418,69.01935,69.01935,0,0,1,24.48539,38.371,71.952,71.952,0,0,1-3.48421,45.25668c-3.37882,7.83812-8.26549,14.90444-12.03622,22.54518-3.51081,7.11405-6.21461,15.38523-4.03016,23.32022,1.91682,6.96281,7.54445,13.14865,14.6094,15.039a15.34168,15.34168,0,0,0,17.06032-6.96473c3.53174-5.569,3.61624-12.86329-1.68647-17.35977a13.01622,13.01622,0,0,0-18.77733,1.87369,13.3982,13.3982,0,0,0-2.68,9.82336c.26123,1.76869-2.44557,2.532-2.70922.74689a15.84886,15.84886,0,0,1,29.55027-10.23364c3.1978,6.01244,1.47241,13.2644-2.58692,18.40376a17.93772,17.93772,0,0,1-18.289,6.5766c-7.3891-1.7065-13.43377-7.57932-16.27641-14.48054-3.16469-7.68307-1.71472-16.25681,1.37036-23.71223,3.33488-8.05909,8.28714-15.28693,12.20348-23.05312a65.656,65.656,0,0,0,6.66212-22.43247,70.98031,70.98031,0,0,0-10.12325-44.96015,65.9702,65.9702,0,0,0-36.33575-27.6153,68.94394,68.94394,0,0,0-45.62064,1.20628c-14.27615,5.625-28.06144,17.88347-27.89591,34.3474.07071,7.03282,2.68145,14.205,8.06841,18.89582,6.43783,5.60593,15.40168,6.08516,23.4945,5.21026,8.14416-.88045,16.05286-3.25891,24.23218-3.86564,7.11426-.52774,14.78947.02091,21.14164,3.55546a21.13822,21.13822,0,0,1,7.34327,6.8122c1.00334,1.51033-1.429,2.91884-2.426,1.418Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M980.03027,542.54545l-3.97021-2.76-7.39014-5.13a6.56354,6.56354,0,0,0-9.12012,1.65l-24.08984,34.67-22.42969,32.28-2,2.88v42.91H911.21a6.53113,6.53113,0,0,0,4.08984-2,5.83071,5.83071,0,0,0,.63038-.77l6.98-10.06,17.25-24.83,17.87988-25.72,1.70019-2.45,21.91993-31.55a5.68185,5.68185,0,0,0,.33007-.52,5.26886,5.26886,0,0,0,.26954-.53A6.54993,6.54993,0,0,0,980.03027,542.54545Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M980.1001,528.82542a16.0877,16.0877,0,0,0-2.22022-1.22,16.35888,16.35888,0,0,0-19.98,5.16l-22.75976,30.86a6.52485,6.52485,0,0,0,2.17968,9.04l20.72022,13,.25976.16,1.04.65a6.57314,6.57314,0,0,0,9.01026-2.2l16.06982-31.9.27-.53.01026-.01.98974-1.99A16.36293,16.36293,0,0,0,980.1001,528.82542Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M840.75,656.01542v75.29a6.5595,6.5595,0,0,0,6.5498,6.55h13.83008a6.55962,6.55962,0,0,0,6.5503-6.55v-75.29Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M943.79,647.04545H913.03027v-61.16a9.34031,9.34031,0,0,0-9.33007-9.33H789.62988a9.34208,9.34208,0,0,0-9.33984,9.33v62.8a9.34207,9.34207,0,0,0,9.33984,9.33H943.79c.16016,0,.31006-.01.46-.02.14014-.01.28027-.04.41992-.06a4.5161,4.5161,0,0,0,3.62012-4.42v-1.97A4.50022,4.50022,0,0,0,943.79,647.04545Zm0,8.97H789.62988a7.34268,7.34268,0,0,1-7.33984-7.33v-62.8a7.34269,7.34269,0,0,1,7.33984-7.33H903.7002a7.34062,7.34062,0,0,1,7.33007,7.33v63.16H943.79a2.501,2.501,0,0,1,2.5,2.5v1.97A2.501,2.501,0,0,1,943.79,656.01542Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <circle cx="693.59457" cy="490.09438" r="7.69014" fill="#f8bc93" />
        <circle cx="121.99614" cy="307.82101" r="53.51916" fill="#f8bc93" />
        <path
            d="M264.62988,636.34544H249.87012a6.50619,6.50619,0,0,0-6.5,6.5v123.01a6.50616,6.50616,0,0,0,6.5,6.5h14.75976a6.50616,6.50616,0,0,0,6.5-6.5v-123.01A6.50619,6.50619,0,0,0,264.62988,636.34544Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M295.81982,636.34544H281.06006a6.50619,6.50619,0,0,0-6.5,6.5v123.01a6.50616,6.50616,0,0,0,6.5,6.5h14.75976a6.49958,6.49958,0,0,0,6.5-6.5v-123.01A6.49961,6.49961,0,0,0,295.81982,636.34544Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M306.50977,516.59544a40.533,40.533,0,0,0-72.58985,24.77v108.94a5.36,5.36,0,0,0,.08008.99,6.99638,6.99638,0,0,0,6.91992,6.01h67.07031a7.01439,7.01439,0,0,0,7-7v-108.94A40.25085,40.25085,0,0,0,306.50977,516.59544Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M320.25837,458.67138l-.05567-2c3.7207-.10351,7.001-.33691,9.46582-2.13769a6.148,6.148,0,0,0,2.38135-4.52832,3.51437,3.51437,0,0,0-1.15283-2.89453c-1.63623-1.38184-4.269-.93457-6.188-.05469l-1.65479.75879,3.17334-23.19043,1.98145.27148-2.69922,19.72656c2.60742-.7666,5.02344-.43652,6.67822.96094a5.471,5.471,0,0,1,1.86035,4.49219,8.13263,8.13263,0,0,1-3.20019,6.07324C327.68122,458.46337,323.56549,458.57861,320.25837,458.67138Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <rect x="150.94172" y="299.4051" width="10.77148" height="2" fill="#2f2e41" />
        <path
            d="M259.37012,518.13547a6.45061,6.45061,0,0,0-4.52-2.09l-11.62989-.48-3.11035-.13a6.51048,6.51048,0,0,0-6.77,6.22l-1.83984,44.32-3.25,78.59a6.51093,6.51093,0,0,0,5.75,6.73c.16016.02.31006.03.47021.04l8.89991.37,5.85009.24a6.46947,6.46947,0,0,0,1.39991-.09,6.535,6.535,0,0,0,3.27-1.62,6.4498,6.4498,0,0,0,2.08984-4.52l.39014-9.37,2.87011-69.31,1.83985-44.23A6.49814,6.49814,0,0,0,259.37012,518.13547Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M250.8501,508.43546a18.08859,18.08859,0,0,0-3.78028-.39,18.52234,18.52234,0,0,0-18.5,18.5v34a6.52768,6.52768,0,0,0,2.93018,5.43,6.40645,6.40645,0,0,0,2.41992.96,6.22942,6.22942,0,0,0,1.1499.11h24a.91982.91982,0,0,0,.17041-.01,6.49573,6.49573,0,0,0,6.32959-6.49v-34A18.52921,18.52921,0,0,0,250.8501,508.43546Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M379.03027,605.26542a7.48409,7.48409,0,0,1-3.70019-8.91l5.68994-18.11-1.66016-1.38-43.66992-36.13-29.18017-24.14-3.54981-2.94a6.49943,6.49943,0,0,0-9.1499.86l-9.41016,11.38a6.48817,6.48817,0,0,0,.85987,9.15l29.73046,24.6,3.37989,2.79,61.66992,51.02a6.49314,6.49314,0,0,0,8.1499.12,5.88986,5.88986,0,0,0,.87988-.85,1.37983,1.37983,0,0,0,.12012-.13l1.18994-1.44Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M333.58984,533.79545l-26.30957-21.53a18.50088,18.50088,0,0,0-23.44043,28.63l26.31006,21.53a6.49642,6.49642,0,0,0,8.22022.01,5.38689,5.38689,0,0,0,.92968-.92L334.5,542.94547a6.42731,6.42731,0,0,0,1.18994-2.21A6.53485,6.53485,0,0,0,333.58984,533.79545Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M261.64522,444.07453c-2.78683-4.65586-4.96791-9.7857-3.93266-15.30212.92444-4.9259,4.46936-8.76216,9.56674-9.26567,3.06966-.30321,6.12471.51626,9.12293,1.05a34.24574,34.24574,0,0,0,7.44791.48247,36.99426,36.99426,0,0,0,14.45114-3.66415,35.46926,35.46926,0,0,0,19.05641-22.33138c3.04174-11.0738.78337-23.64867-6.44032-32.66261l2.50708-.6619-3.26431,10.72431a1.51517,1.51517,0,0,1-2.2035.89645,51.66415,51.66415,0,0,0-40.20064-6.42726,48.94693,48.94693,0,0,0-11.031,4.24282c-1.70393.9059-3.22136-1.68277-1.51416-2.59041a54.69729,54.69729,0,0,1,42.91465-3.28751,52.21372,52.21372,0,0,1,11.34528,5.47195l-2.2035.89644,3.26432-10.72431a1.512,1.512,0,0,1,2.50707-.6619,40.20712,40.20712,0,0,1,8.24122,30.48283,37.9402,37.9402,0,0,1-16.42866,26.31876,40.45782,40.45782,0,0,1-30.55368,6.14207c-2.9359-.56438-6.26537-1.3723-9.14605-.14293a7.90015,7.90015,0,0,0-4.234,5.2076c-1.63237,5.53133,1.14517,10.78409,3.98245,15.38331Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#2f2e41"
        />
        <path
            d="M456.89014,554.87546l-49.47022-28.63a9.50343,9.50343,0,0,0-13.81982,5.37l-14.24024,45.25-5.93994,18.89a9.4829,9.4829,0,0,0,4.68994,11.28l10.96,5.69,35.76026,18.55a9.4782,9.4782,0,0,0,4.37988,1.07,9.65469,9.65469,0,0,0,3.29981-.59l.07031-.03a9.55011,9.55011,0,0,0,5.5-5.48L461,566.49546A9.45851,9.45851,0,0,0,456.89014,554.87546Zm2.23974,10.91-22.91992,59.75a7.5288,7.5288,0,0,1-4.3501,4.32l-.0498.02a7.53226,7.53226,0,0,1-6.06006-.37l-35.37012-18.35-11.34961-5.89a7.48409,7.48409,0,0,1-3.70019-8.91l5.68994-18.11,14.48-46.03a7.51712,7.51712,0,0,1,10.91016-4.24l49.46972,28.63A7.46953,7.46953,0,0,1,459.12988,565.78544Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M440.29233,574.01932a2.98986,2.98986,0,0,1-1.43384-.3667l-33-18a3.00008,3.00008,0,0,1,2.873-5.26758l33,18a3.00037,3.00037,0,0,1-1.43921,5.63428Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M434.29233,587.01932a2.98986,2.98986,0,0,1-1.43384-.3667l-33-18a3.00008,3.00008,0,0,1,2.873-5.26758l33,18a3.00037,3.00037,0,0,1-1.43921,5.63428Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M429.29233,601.01932a2.98986,2.98986,0,0,1-1.43384-.3667l-33-18a3.00008,3.00008,0,0,1,2.873-5.26758l33,18a3.00037,3.00037,0,0,1-1.43921,5.63428Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M778.2656,317.73492H639.537a7.53,7.53,0,0,1-7.52143-7.52143v-175.5a7.53,7.53,0,0,1,7.52143-7.52143H778.2656a7.53005,7.53005,0,0,1,7.52143,7.52143v175.5A7.53005,7.53005,0,0,1,778.2656,317.73492ZM639.537,128.86349a5.85657,5.85657,0,0,0-5.85,5.85v175.5a5.85657,5.85657,0,0,0,5.85,5.85H778.2656a5.85658,5.85658,0,0,0,5.85-5.85v-175.5a5.85658,5.85658,0,0,0-5.85-5.85Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M697.36117,211.55048h-30.537a3.76489,3.76489,0,0,1-3.76072-3.76071V171.75408a3.76489,3.76489,0,0,1,3.76072-3.76071h30.537a3.76488,3.76488,0,0,1,3.76071,3.76071v36.03569A3.76488,3.76488,0,0,1,697.36117,211.55048Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M756.69688,228.26477h-30.537a3.76489,3.76489,0,0,1-3.76071-3.76071V188.46837a3.76489,3.76489,0,0,1,3.76071-3.76072h30.537a3.7649,3.7649,0,0,1,3.76072,3.76072v36.03569A3.7649,3.7649,0,0,1,756.69688,228.26477Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <path
            d="M709.06117,279.24334h-30.537a3.76489,3.76489,0,0,1-3.76072-3.76071V239.44694a3.7649,3.7649,0,0,1,3.76072-3.76072h30.537a3.76489,3.76489,0,0,1,3.76071,3.76072v36.03569A3.76488,3.76488,0,0,1,709.06117,279.24334Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ff6584"
        />
        <path
            d="M597.30478,270.29886a7.60179,7.60179,0,0,0-6.94971-4.65h-138.73a7.52416,7.52416,0,0,0-7.52,7.52v175.5a7.52414,7.52414,0,0,0,7.52,7.51995h138.73a7.53041,7.53041,0,0,0,7.52-7.51995v-175.5A7.38318,7.38318,0,0,0,597.30478,270.29886Zm-1.09961,178.37a5.85746,5.85746,0,0,1-5.8501,5.85h-138.73a5.85119,5.85119,0,0,1-5.8501-5.85v-175.5a5.85763,5.85763,0,0,1,5.8501-5.85h138.73a5.87941,5.87941,0,0,1,5.43018,3.68,6.493,6.493,0,0,1,.21973.66,5.7954,5.7954,0,0,1,.20019,1.51Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M568.34965,307.08483H526.56394a3.34286,3.34286,0,1,1,0-6.68572h41.78571a3.34286,3.34286,0,0,1,0,6.68572Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M568.34965,324.63483H526.56394a3.34286,3.34286,0,1,1,0-6.68572h41.78571a3.34286,3.34286,0,1,1,0,6.68572Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#3f3d56"
        />
        <path
            d="M505.12354,334.62784h-30.537a3.7649,3.7649,0,0,1-3.76072-3.76071V294.83144a3.7649,3.7649,0,0,1,3.76072-3.76072h30.537a3.7649,3.7649,0,0,1,3.76072,3.76072v36.03569A3.7649,3.7649,0,0,1,505.12354,334.62784Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#f8bc93"
        />
        <path
            d="M568.06012,360.57054H473.6244a3.34286,3.34286,0,1,1,0-6.68571h94.43572a3.34286,3.34286,0,0,1,0,6.68571Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <path
            d="M568.06012,378.12054H473.6244a3.34286,3.34286,0,1,1,0-6.68571h94.43572a3.34285,3.34285,0,0,1,0,6.68571Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <path
            d="M568.06012,395.67054H473.6244a3.34286,3.34286,0,1,1,0-6.68571h94.43572a3.34285,3.34285,0,0,1,0,6.68571Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <path
            d="M568.06012,413.22054H473.6244a3.34286,3.34286,0,1,1,0-6.68571h94.43572a3.34285,3.34285,0,0,1,0,6.68571Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <path
            d="M568.06012,430.77054H473.6244a3.34286,3.34286,0,1,1,0-6.68571h94.43572a3.34286,3.34286,0,0,1,0,6.68571Z"
            transform="translate(-153.06664 -127.19206)"
            fill="#ccc"
        />
        <rect
            x="608.89503"
            y="374.31831"
            width="109.19622"
            height="1.67162"
            transform="translate(-228.79203 433.75692) rotate(-43.59326)"
            fill="#e6e6e6"
        />
        <polygon
            points="469.569 286.858 479.607 286.74 479.589 285.067 471.347 285.166 471.851 276.94 470.181 276.839 469.569 286.858"
            fill="#e6e6e6"
        />
        <polygon
            points="551.281 209.063 550.671 219.083 549.001 218.983 549.504 210.756 541.263 210.855 541.245 209.183 551.281 209.063"
            fill="#e6e6e6"
        />
        <circle cx="510.42648" cy="247.962" r="9.19286" fill="#e6e6e6" />
        <rect
            x="502.89503"
            y="196.31831"
            width="109.19622"
            height="1.67162"
            transform="translate(-135.28409 311.5833) rotate(-43.59326)"
            fill="#e6e6e6"
        />
        <polygon
            points="363.569 108.858 373.607 108.74 373.589 107.067 365.347 107.166 365.851 98.94 364.181 98.839 363.569 108.858"
            fill="#e6e6e6"
        />
        <polygon
            points="445.281 31.063 444.671 41.083 443.001 40.983 443.504 32.756 435.263 32.855 435.245 31.183 445.281 31.063"
            fill="#e6e6e6"
        />
        <circle cx="404.42648" cy="69.962" r="9.19286" fill="#e6e6e6" />
        <rect x="757.93336" y="520.91339" width="2" height="9" fill="#3f3d56" />
    </svg>
);

export default ActionDraw;
